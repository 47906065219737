<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ DeleteDialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="apagar">{{ModalDeleteText}}</v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$primary" text @click="close">{{$t('actions.cancel')}}</v-btn>
        <v-btn :color="$primary" text @click="eliminar">{{$t('actions.confirm')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CRUD from "@/mixins/_CRUD"
export default {
  mixins: [CRUD],
  props: {
    ModalDeleteText: String,
    DeleteDialogTitle: String,
    MyObject: Object,
    MyIndex: Number,
    Store: String
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    }
  },
  data: () => ({
    dialog: false
  }),
  watch: {
    dialog(val) {
      val || this.close()

      this.$forceUpdate()
    }
  },
  mounted() {},
  created() {
    this.$eventBus.$on("DeleteDialog", state => {
      this.dialog = state
    })
  },
  methods: {
    close() {
      this.dialog = false

      setTimeout(() => {
        this.$eventBus.$emit("ClearDialog")
      }, 110)
    }
  }
}
</script>
<style scoped>
.apagar {
  line-height: 30px !important;
  font-size: 17px !important;
}
</style>
